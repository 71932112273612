




















































































import {Component, Watch} from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {IPatient} from '@/interfaces/patients';
import {dispatchDeleteRequisition, dispatchGetRequisitionsForList} from '@/store/crud/actions';
import {IVisit} from '@/interfaces/visits';

@Component({components: {ConfirmationModal}})
export default class AdminRequisitions extends AppComponent {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'patient.id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'patient.fname',
      align: 'left',
    },
    {
      text: 'Last Name',
      sortable: true,
      value: 'patient.lname',
      align: 'left',
    },
    {
      text: 'email',
      sortable: true,
      value: 'patient.patientContactInfo.email',
      align: 'left',
    },
    {
      text: 'identifier',
      sortable: true,
      value: 'patient.patientIdentifier',
      align: 'left',
    },
    {
      text: 'Firebase ID',
      sortable: true,
      value: 'patient.patientFirebaseId',
      align: 'left',
    },
    {
      text: 'Reviewed',
      sortable: true,
      value: 'reviewed',
      align: 'center',
    },
    {
      text: 'approved',
      sortable: true,
      value: 'confirmation',
      align: 'center',
    },
    {
      text: 'ready for confirmation',
      sortable: true,
      value: 'readyForConfirmation',
      align: 'center',
    },
    {
      text: 'number of visits',
      sortable: true,
      value: 'visits.length',
      align: 'center',
    },
    {
      text: 'State',
      sortable: true,
      value: 'requisitionState',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public visitDates = {};
  public fetchingData = false;
  public fetchingVisitDates = false;

  public created() {
    this.fetchData();
  }

  public async fetchData() {
    this.fetchingData = true;
    await dispatchGetRequisitionsForList(this.$store);
    this.fetchingData = false;
  }

  public get fetching() {
    return this.fetchingData || this.fetchingVisitDates;
  }

  /**
   * Delete a results and refresh the screen
   */
  public async deleteRequisition(requisitionId: string) {
    await dispatchDeleteRequisition(this.$store, requisitionId);
    await this.fetchData();
  }

  /**
   * Disabled to avoid performance issues in the requisitions list
   * Instead, a check showing if the requisition contains visit dates will be shown
   */
  // @Watch('requisitions')
  public async setFormattedVisitDates() {
    try {
      const visitsList: IVisit[] = [];
      // make a complete list of all visits of all requisitions to do a batch request
      for (const requisition of this.requisitions) {
        if (requisition.visits && requisition.visits.length > 0) {
          visitsList.push(...(requisition.visits as IVisit[]));
        }
      }
      this.fetchingVisitDates = true;
      // make a batch request to get all visits
      const _visitDates = await this.getBatchVisitDateTime(visitsList);
      // of no results, we abort operation
      if (!_visitDates || Object.keys(_visitDates).length === 0) {
        return;
      }
      // wrap all the visits under their corresponding requisition id
      const visitsDatesForRequisitions: any = {};
      for (const requisition of this.requisitions) {
        const requisitionDatetime: string[] = [];
        if (requisition.visits && requisition.visits.length > 0) {
          for (const visit of requisition.visits as IVisit[]) {
            if (visit.id) {
              if (_visitDates[visit.id] && _visitDates[visit.id].length > 0) {
                requisitionDatetime.push(_visitDates[visit.id]);
              }
            }
          }
        }
        if (requisitionDatetime.length > 0) {
          visitsDatesForRequisitions[requisition.id!] = requisitionDatetime.join(', ');
        }
      }
      this.visitDates = visitsDatesForRequisitions;
    } catch (error) {
      // FIXME: important log here
    } finally {
      this.fetchingVisitDates = false;
    }
  }

  public getFormattedPatientName(patient: IPatient) {
    if (patient) {
      if (patient.fname && patient.lname) {
        return `${patient.fname} ${patient.lname}`;
      } else if (patient.fname && !patient.lname) {
        return `${patient.fname}`;
      } else if (!patient.fname && patient.lname) {
        return `${patient.lname}`;
      } else if (patient.patientContactInfo && patient.patientContactInfo.email) {
        return `${patient.patientContactInfo.email}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
