var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',{staticClass:"display-1 header--text"},[_vm._v(" Manage requisitions ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/requisitions/create"}},[_vm._v("Create requisition")])],1),(_vm.fetchingData)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.requisitions},scopedSlots:_vm._u([{key:"item.patient.id",fn:function(props){return [(props.item.patient && props.item.patient.id)?_c('router-link',{attrs:{"target":"_blank","to":{name: 'main-admin-patients-edit', params: {id: props.item.patient.id}}}},[_vm._v(" "+_vm._s(props.item.patient.id)+" ")]):_vm._e()]}},{key:"item.visitDate",fn:function(props){return [(_vm.fetchingVisitDates)?_c('v-skeleton-loader',{attrs:{"type":"table-cell"}}):_c('div',[_vm._v(" "+_vm._s(_vm.visitDates[props.item.id])+" ")])]}},{key:"item.id",fn:function(props){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                  name: 'main-admin-requisitions-edit',
                  params: {requisitionId: props.item.id},
                },"color":"accent"}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"dark"}},[_vm._v("edit")]),_vm._v(" Edit ")],1)],1),_c('ConfirmationModal',{attrs:{"buttonLabel":"Delete","listItem":"","modalWidth":450,"title":"Delete Requisition?","confirmationLabel1":("Are you sure you want to delete " + (_vm.getFormattedPatientName(
                    props.item.patient
                  )) + "'s requisition?"),"confirmationLabel2":"This action will delete all the requisition data, including all the visits and biomarker results.","cancelButtonLabel":"Cancel","confirmButtonLabel":"Delete"},on:{"confirm":function($event){return _vm.deleteRequisition(props.item.id)}}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }